<template>
  <div class="exercise-list-page w-100">
    <basic-subheader title="">
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon mr-6">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Hướng dẫn"
                    placeholder="Nhập hướng dẫn "
                    name="codeParams"
                    :value.sync="search.question"
                  ></basic-input>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col>
                  <basic-input
                    label="Nội dung"
                    placeholder="Nhập nội dung hướng dẫn "
                    name="codeParams"
                    :value.sync="search.answer"
                  ></basic-input>
                </b-col>
              </b-row> -->
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-2"
          type="button"
          @click="handleCreate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Tạo hướng dẫn
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <template-table
          :column="headers"
          :data="data"
          :paging="paging"
          :tableAction="false"
          :selectAction="false"
          :searchAction="false"
          @search="searchRequest"
          @reset="resetRequest"
        >
          <template v-slot:body="{ item }">
            <td class="cell-overflow">
              <div class="text-overflow">
                {{ item.question }}
              </div>
            </td>
            <td class="cell-overflow">
              <div class="text-overflow">
                {{ convertHtmlToText(item.answer) }}
              </div>
            </td>
            <td>
              <div v-if="isWritePermission" style="width: 100px">
                <a
                  class="btn btn-icon btn-sm"
                  @click.stop="handleUpdate(item)"
                  style="
                    background-color: #e6f2f8;
                    border: none;
                    color: #407bff;
                  "
                  title="Chỉnh sửa"
                >
                  <span class="menu-icon svg-icon svg-icon-sm icon-edit">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </a>
                <a
                  class="btn btn-icon btn-danger btn-sm ml-4"
                  @click.stop="handleDelete(item)"
                  style="
                    background-color: #e6f2f8;
                    border: none;
                    color: #f64e60;
                  "
                  title="Chỉnh sửa"
                >
                  <span class="menu-icon svg-icon svg-icon-sm icon-delete">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                    />
                  </span>
                </a>
              </div>
            </td>
          </template>
        </template-table>
      </b-overlay>
      <update-question
        :id.sync="id"
        @loadData="loadData"
        :popupType="popupType"
      />
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'UserGuide',
  components: { 'update-question': () => import('./components/Modal') },
  data() {
    return {
      loading: false,
      isExpandedNew: true,
      isExpandedOld: true,
      search: {
        key: null,
        value: null,
      },
      filter: {
        sortBy: null,
        sortDesc: null,
      },
      expanded: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },

      headers: [
        {
          label: 'Hướng dẫn',
          key: 'question',
          sortable: false,
          style: 'width: 20%;',
        },
        {
          label: 'Nội dung',
          key: 'answer',
          sortable: false,
          style: 'width: 70%;',
        },
        { label: '', key: '', sortable: false, style: 'width: 100px;' },
      ],
      data: [],
      detail: {},
      options: {},
      id: null,
      popupType: null,
    };
  },
  watch: {
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    options: {
      handler(newVal) {
        let { sortBy, sortDesc } = newVal;
        let [order] = sortDesc;
        let [sort] = sortBy;
        this.filter.sortBy = sort;

        this.filter.sortDesc = order ? 'desc' : 'asc';
      },
      deep: true,
    },
  },
  computed: {
    // FIX: fix update list after sort
    searchParams() {
      return {
        page: this.paging.page,
        size: this.paging.pageSize,
        question: this.search.question,
        answer: this.search.answer,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },

  },
  created() {
    this.loadData();
  },
  methods: {
    convertHtmlToText(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html; 
      return tempDiv.innerText || tempDiv.textContent; 
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.search.question = null;
      this.search.answer = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
      document.body.click();
    },
    async loadData() {
      this.loading = true;
      try {
        const { data, meta } = await this.$api.get('Profile/Instruction', {
          params: this.searchParams,
        });
        this.data = data || [];
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e(error, 'ERROR');
      } finally {
        this.loading = false;
      }
    },
    handleUpdate(payload) {
      this.popupType = 'edit';
      this.id = payload.id;
      this.$bvModal.show('question-modal');
    },
    handleCreate() {
      this.popupType = 'create';
      this.$bvModal.show('question-modal');
    },
    async handleDelete(item) {
      let confirm = await this.$swal({
        title: 'Xác nhận?',
        text: `Bạn có muốn xóa  đối tượng đã chọn`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      });
      if (!confirm) return;
      try {
        await this.$api.delete(`Profile/Instruction/Input/${item.id}`);
        this.$toastr.s('Xoá thành công', 'SUCCESS');
        this.loadData();
      } catch (error) {
        this.$toastr.e(error, 'ERROR');
      }
    },
    expandAll() {
      this.isExpandedNew = !this.isExpandedOld;
      // Filter vue-components
      Object.keys(this.$refs)
        .filter((e) => this.$refs[e]?.$el)
        .forEach((k) => {
          // Check if element has already open
          const status = this.$refs[k].$attrs['data-open'];
          if (status != this.isExpandedNew) {
            this.$refs[k].$el.click();
          }
          return;
        });
      this.isExpandedOld = this.isExpandedNew;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-edit {
  background-color: #e6f2f8;
  border: none;
  color: #407bff;
}
.icon-delete {
  border: none;
  color: #f64e60;
}
.mw-400 {
  min-width: 400px;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
